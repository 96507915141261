<template>
  <div class="clothing-items">
    <div class="heading-accent" v-if="title">{{ title }}</div>

    <div class="clothing-items-list"
         :class="$mq">
       <template v-for="clothingItem in clothingItems">
          <clothing-item-card v-if="clothingItem.clothingItemType === 'existingClothing'"
                              class="clothing-item"
                              :key="clothingItem.id"
                              :clothing-item="clothingItem"
                              :size="clothingItemCardSize"
                              showWearWithButton/>
       </template>
    </div>

    <infinite-loading @infinite="loadClothingItems" ref="infiniteLoading">
      <div slot="spinner" class="loader-wrapper">
        <base-loader></base-loader>
      </div>

      <div class="empty-filler"
           slot="no-results">
        <base-icon icon="emptyClothing"
                   width="100px"
                   height="100px"></base-icon>
        <p>{{ $t('catalogue.clothingItems.nothingFound') }}</p>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import clothingItemService from '@/services/queries/clothingItemQueries'
import ClothingItemCard from '@/components/Shared/ClothingItemCard/ClothingItemCard.vue'
import bus from '@/bus'
import InfiniteLoading from 'vue-infinite-loading'

const defaultGender = 'female'
const desktopLimit = 50
const mobileLimit  = 50

export default {
  props: {
    title: {
      required: false,
      type: String
    },

    filters: {
      required: true,
      type: Object
    }
  },

  components: {
    ClothingItemCard,
    InfiniteLoading
  },

  data() {
    return {
      clothingItems: [],

      limit: desktopLimit,
      offset: 0,

      noMoreItems: false
    }
  },

  created() {
    // TODO: Tried to put $mq directly into data, didn't work, check it out
    if (this.$mq === 'mobile') {
      this.limit = mobileLimit
    }
  },

  methods: {
    loadClothingItems ($state) {
      if (this.noMoreItems) {
        return false
      }

      let params = {...this.filters}

      params.gender = this.$route.params.gender || defaultGender
      params.limit  = this.limit
      params.offset = this.offset

      const promise = clothingItemService.get(params)
      this.lastPromise = promise

      new Promise((resolve, reject) => {
        promise.then((result) => {
          if (promise == this.lastPromise) {
            this.lastPromise = null
            resolve(result)
          }
        })
      }).then(data => {
        this.offset = this.offset + this.limit

        if (data.items.length === 0) {
          this.noMoreItems = true
        }

        this.clothingItems.push(...data.items)

        if (data.items.length) {
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  },

  computed: {
    clothingItemCardSize() {
      if (this.$mq === 'mobile') {
        return 'mobile'
      } else {
        return 'medium'
      }
    }
  },

  watch: {
    filters: {
      handler() {
        this.clothingItems = []
        this.offset = 0

        this.$nextTick(() => {
          this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
        })
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .clothing-items {
    .clothing-items-list {
      display: flex;
      flex-wrap: wrap;

      &.mobile, &.tablet {
        justify-content: space-evenly;
      }
    }

    .clothing-item {
      cursor: pointer;
      margin: 0 0 30px 20px;
    }

    .loader-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 40px 0;
    }

    .empty-filler {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 40px 0;

      & > p {
        font-size: 18px;
        color: #797979;
      }
    }
  }
</style>
