<template>
  <li class="catalogue-categories-sales">
    <a href="#" class="link">
      <span ref="salesOpener">{{ $t('catalogue.catalogueCategoriesSales.label') }}</span>
    </a>

    <div class="sales-dropdown" ref="salesDropdown">
      <div class="category"
           v-for="category in categories"
           :key="category.id">
        <router-link class="link category-link"
                     :to="getCategoryLink(category)"
                     active-class="faked-active">
          {{ category.title }}
        </router-link>
        <ul class="list"
            v-if="getSubCategories(category.clothing_category_id).length > 0">
          <li v-for="subCategory in getSubCategories(category.clothing_category_id)"
              :key="subCategory.clothing_subcategory_id">
            <router-link class="link subcategory-link"
                         active-class="faked-active"
                         :to="getSubCategoryLink(subCategory)">
              {{ subCategory.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    props: {
      gender: {
        required: true,
        type: String
      }
    },

    mounted() {
      tippy(this.$refs.salesOpener, {
        content: this.$refs.salesDropdown,
        maxWidth: 'none',
        interactive: true,
        interactiveBorder: 30,
        theme: 'light dropdown-menu',
        animation: 'shift-away',
        arrow: true,
        placement: 'right',
        inertia: true,
        duration: [600, 300],
        offset: [0, 50]
      })
    },

    methods: {
      getCategoryLink(category) {
        let query = Object.assign({}, this.$route.query, {
          clothing_category_id: category.clothing_category_id,
          is_sale: true
        })

        query = _.omit(query, [
          'is_liked',
          'is_new',
          'priority.min',
          'priority.max',
          'clothing_subcategory_id'
        ])

        return {
          name: 'catalogue',
          params: {
            gender: this.gender
          },
          query
        }
      },

      getSubCategoryLink(subCategory) {
        let query = Object.assign({}, this.$route.query, {
          clothing_subcategory_id: subCategory.clothing_subcategory_id,
          is_sale: true
        })

        query = _.omit(query, [
          'is_liked',
          'is_new',
          'priority.min',
          'priority.max',
          'clothing_category_id'
        ])

        return {
          name: 'catalogue',
          params: {
            gender: this.gender
          },
          query
        }
      },
    },

    computed: {
      ...mapGetters('handbooks', {
        getCategories: 'getClothingCategories'
      }),

      ...mapGetters('handbooks', {
        getSubCategories: 'getClothingSubCategoriesByCategoryId'
      }),

      categories() {
        return this.getCategories(this.gender)
      }
    }
  }
</script>

<style lang="scss" scoped>


  .catalogue-categories-sales {
    .link {
      color: #000;
      display: inline-block;
      width: 100%;
      padding: 4px 4px 4px 0;

      font-size: 14px;

      &.active, &:hover {
        color: $primary-color;
      }
    }
  }

  .sales-dropdown {
    display: flex;
    padding: 10px;

    .category {
      margin-right: 60px;
    }

    .list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .category-list > li {
      padding: 10px 0;
    }

    .link {
      display: inline-block;
      width: 100%;
      padding: 4px 4px 4px 0;

      font-size: 14px;

      &.active, &:hover {
        color: $primary-color;
      }
    }

    .category-link {
      color: #000;
    }
  }
</style>
