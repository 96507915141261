<template>
  <base-menu-slide class="catalogue-categories-mobile"
                   :show="show"
                   @close="$emit('close')">
    <div class="content">
      <clothing-gender-select v-model="internalGender"
                              class="gender"></clothing-gender-select>
      <ul class="menu">
        <li class="menu-item">
          <router-link :to="getCategoryLink({ is_liked: 'true' })"
                       class="link category-link">
            {{ $t('catalogue.categoryTree.favorite') }}
          </router-link>
        </li>

        <li class="menu-item">
          <router-link :to="getCategoryLink({ is_new: 'true', is_sale: 'false' })"
                       class="link category-link">
            {{ $t('catalogue.categoryTree.new') }}
          </router-link>
        </li>

        <!--<li class="menu-item">-->
          <!--<router-link :to="{ path: 'catalogue', query: { 'priority.min': '2.0' }}" class="link category-link">-->
            <!--{{ $t('catalogue.categoryTree.trends') }}-->
          <!--</router-link>-->
        <!--</li>-->

        <li class="menu-item" v-for="category in categories" :key="category.id">
          <router-link class="link category-link"
                       :class="{ active: isCategoryActive(category) }"
                       active-class="faked-active"
                       :to="getCategoryLink({ clothing_category_id: category.clothing_category_id})">
            {{ category.title }}
          </router-link>

          <ul class="menu sub-menu"
              v-if="getSubCategories(category.clothing_category_id).length > 0">

            <li class="menu-item"
                v-for="subCategory in getSubCategories(category.clothing_category_id)"
                :key="subCategory.clothing_subcategory_id">
              <router-link class="link subcategory-link"
                           :class="{ active: isSubCategoryActive(subCategory) }"
                           active-class="faked-active"
                           :to="getCategoryLink({ clothing_subcategory_id: subCategory.clothing_subcategory_id })">
                {{ subCategory.title }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </base-menu-slide>
</template>

<script>
  import ClothingGenderSelect from '@/components/Shared/ClothingGenderSelect.vue'

  export default {
    props: {
      show: {
        required: true,
        type: Boolean
      },

      gender: {
        required: true
      }
    },

    components: {
      ClothingGenderSelect
    },

    data() {
      return {
        internalGender: this.gender
      }
    },

    methods: {
      getCategoryLink(filter) {
        let query = Object.assign({}, this.$route.query, filter)

        let allCategories = [
          'is_liked',
          'is_sale',
          'is_new',
          'priority.min',
          'priority.max',
          'clothing_category_id',
          'clothing_subcategory_id'
        ]

        Object.entries(filter).forEach(([key, value]) => {
          let index = allCategories.indexOf(key)
          allCategories.splice(index, 1)
        })

        query = _.omit(query, allCategories)

        return {
          name: 'catalogue',
          params: {
            gender: this.$route.params.gender
          },
          query
        }
      },

      isCategoryActive(category) {
        return category.clothing_category_id == this.$route.query.clothing_category_id
      },

      isSubCategoryActive(subCategory) {
        return subCategory.clothing_subcategory_id == this.$route.query.clothing_subcategory_id
      }
    },

    computed: {
      ...mapGetters('handbooks', {
        getCategories: 'getClothingCategories'
      }),

      ...mapGetters('handbooks', {
        getSubCategories: 'getClothingSubCategoriesByCategoryId'
      }),

      categories() {
        return this.getCategories(this.internalGender)
      }
    }
  }
</script>

<style lang="scss" scoped>


  .catalogue-categories-mobile {
    .content {
      padding: 10px;

      .gender {
        margin-top: 10px;
      }

      .menu {
        display: block;
        padding: 0;
        list-style: none;

        .menu-item {
          padding: 0 10px;

          & > a {
            display: flex;
            width: 100%;
            text-size-adjust: 100%;
            user-select: none;
            font-size: 16px;
            line-height: 48px;

            &.active {
              color: $primary-color;
            }
          }

          &.with-icon > a {
            align-items: center;
          }
        }

        .menu-item-divider {
          margin: 20px 0;
          border-bottom: 1px solid #eee;
        }
      }

      .sub-menu {
        margin: 0;
        padding-left: 20px;
      }
    }
  }
</style>
