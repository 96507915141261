<template>
  <div class="catalogue-mobile">
    <div class="catalogue-mobile-header">
      <div class="top">
        <a href="#" @click.prevent="openCategories">
          {{ $t('catalogue.catalogueMobile.categories') }}
        </a>
        <a href="#" @click.prevent="openFilters">
          {{ $t('catalogue.catalogueMobile.filters') }}
        </a>
      </div>

      <catalogue-mobile-categories :show="showCategories"
                                   :gender="gender"
                                   @close="showCategories = false"></catalogue-mobile-categories>

      <catalogue-mobile-filters :show="showFilters"
                                v-model="filters"
                                @close="showFilters = false"></catalogue-mobile-filters>
    </div>

    <catalogue-clothing-items :title="clothingItemsTitle"
                              :filters="$route.query"></catalogue-clothing-items>
  </div>
</template>

<script>
  import CatalogueMobileCategories from './CatalogueMobileCategories.vue'
  import CatalogueMobileFilters from './CatalogueMobileFilters.vue'
  import CatalogueClothingItems from '../CatalogueClothingItems.vue'
  import catalogueMixin from '../catalogueMixin.js'

  export default {
    components: {
      CatalogueMobileCategories,
      CatalogueMobileFilters,
      CatalogueClothingItems
    },

    mixins: [
      catalogueMixin
    ],

    data() {
      return {
        showCategories: false,
        showFilters: false
      }
    },

    methods: {
      openCategories() {
        this.showCategories = true
      },

      openFilters() {
        this.showFilters = true
      }
    },

    watch: {
      $route(to, from) {
        this.showCategories = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .catalogue-mobile-header {
    .top {
      display: flex;
      justify-content: space-between;
      padding: 15px;

      > a {
        font-size: 16px;
      }
    }
  }
</style>