<template>
  <div class="banner">
    <img src="@/assets/img/banner_get_inspired.png"
         alt="Get inspired"
         class="full-width"
         @click="openModal"/>
  </div>
</template>

<script>
export default {
  methods: {
    openModal () {
      this.$modal.show('wearGeneration')
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  cursor: pointer;
}
</style>
