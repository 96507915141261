<template>
  <div class="row">
    <div class="col-md-6">
      <learn-style />
    </div>
    <div class="col-md-6">
      <get-inspired />
    </div>
  </div>
</template>

<script>
import GetInspired from './BannersGetInspired.vue'
import LearnStyle from './BannersLearnMyStyle.vue'

export default {
  components: {
    GetInspired,
    LearnStyle
  }
}
</script>
