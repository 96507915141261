<template>
  <div class="banner">
    <img src="@/assets/img/banner_learn_my_style.png"
         alt="Learn my style"
         class="full-width"
         @click="openModal" />
  </div>
</template>

<script>
export default {
  methods: {
    openModal () {
      this.$modal.show('typeDefinition')
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  cursor: pointer;
}
</style>
