<template>
  <div class="catalogue-desktop-categories sticky" ref="categoryTree">
    <div class="heading">{{ $t('catalogue.categoryTree.title') }}</div>

    <ul class="list category-list">
      <catalogue-desktop-categories-sales :gender="gender"></catalogue-desktop-categories-sales>

      <li>
        <router-link :to="getCategoryLink({ is_liked: true })"
                     class="link category-link">
          {{ $t('catalogue.categoryTree.favorite') }}
        </router-link>
      </li>

      <li>
        <router-link :to="getCategoryLink({ is_new: true, is_sale: false })"
                     class="link category-link">
          {{ $t('catalogue.categoryTree.new') }}
        </router-link>
      </li>

      <!--<li>-->
        <!--<router-link :to="getCategoryLink({ 'priority.min': '1.0', 'priority.max': '1.5' })"-->
                     <!--class="link category-link">-->
          <!--{{ $t('catalogue.categoryTree.trends') }}-->
        <!--</router-link>-->
      <!--</li>-->

      <li v-for="category in categories"
          :key="category.id">
        <router-link class="link category-link"
                     :class="{ active: isCategoryActive(category) }"
                     active-class="faked-active"
                     :to="getCategoryLink({ clothing_category_id: category.clothing_category_id})">
          {{ category.title }}
        </router-link>

        <ul class="list"
            v-if="getSubCategories(category.clothing_category_id).length > 0">

          <li v-for="subCategory in getSubCategories(category.clothing_category_id)"
              :key="subCategory.clothing_subcategory_id">
            <router-link class="link subcategory-link"
                         :class="{ active: isSubCategoryActive(subCategory) }"
                         active-class="faked-active"
                         :to="getCategoryLink({ clothing_subcategory_id: subCategory.clothing_subcategory_id })">
              {{ subCategory.title }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
  import CatalogueDesktopCategoriesSales from './CatalogueDesktopCategoriesSales.vue'

  export default {
    props: {
      gender: {
        required: true,
        type: String
      }
    },

    components: {
      CatalogueDesktopCategoriesSales
    },

    methods: {
      getCategoryLink(filter) {
        let query = Object.assign({}, this.$route.query, filter)

        let allCategories = [
          'is_liked',
          'is_sale',
          'is_new',
          'priority.min',
          'priority.max',
          'clothing_category_id',
          'clothing_subcategory_id'
        ]

        Object.entries(filter).forEach(([key, value]) => {
          let index = allCategories.indexOf(key)
          allCategories.splice(index, 1)
        })

        query = _.omit(query, allCategories)

        return {
          name: 'catalogue',
          params: {
            gender: this.$route.params.gender
          },
          query
        }
      },

      isCategoryActive(category) {
        return category.clothing_category_id == this.$route.query.clothing_category_id
      },

      isSubCategoryActive(subCategory) {
        return subCategory.clothing_subcategory_id == this.$route.query.clothing_subcategory_id
      }
    },

    computed: {
      ...mapGetters('handbooks', {
        getCategories: 'getClothingCategories'
      }),

      ...mapGetters('handbooks', {
        getSubCategories: 'getClothingSubCategoriesByCategoryId'
      }),

      categories() {
        return this.getCategories(this.gender)
      }
    }
  }
</script>

<style lang="scss" scoped>


  .sticky {
    position: sticky;
    align-self: flex-start;
  }

  .catalogue-desktop-categories {
    padding-bottom: 20px;

    .heading {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
    }

    .list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .category-list > li {
      padding: 10px 0;
    }

    .link {
      display: inline-block;
      width: 100%;
      padding: 4px 4px 4px 0;

      font-size: 14px;

      &.active, &:hover {
        color: $primary-color;
      }
    }

    .category-link {
      color: #000;
    }
  }
</style>
