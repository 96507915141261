<template>
  <base-menu-slide class="catalogue-mobile-filters"
                   :show="show"
                   slide-from="right"
                   @close="$emit('close')">
    <div class="header">
      {{ $t('catalogue.catalogueMobile.catalogueMobileFilters.header') }}
    </div>
    <div class="content">
      <base-text-box-search :placeholder="$t('catalogue.catalogueMobile.catalogueMobileFilters.searchPlaceholder')"
                            class="search"
                            @input="onSearchInput" />

      <filter-selector :title="$t('catalogue.catalogueMobile.catalogueMobileFilters.partners')"
                       :options="availablePartners"
                       :selected-items="internalFilters.partners"
                       @update="updateFilters('partners', $event)">
      </filter-selector>

      <portal-target name="filterPanelPortal" slim></portal-target>
    </div>
  </base-menu-slide>
</template>

<script>
import FilterSelector from '@/components/Shared/ClothingFilters/FilterSelector/FilterSelector.vue'
import bus from '@/bus'

const defaultFilters = {
  partners: [],
  sorting: 'partner_id{asc}'
}

export default {
  model: {
    prop: 'filters',
    event: 'update'
  },

  props: {
    filters: {
      required: true,
      type: Object
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  components: {
    FilterSelector
  },

  data() {
    return {
      internalFilters: [],

      filtersDisplayed: {
        partners: false
      },

      sorting: 'partner_id{asc}',
      goodsAmount: null
    }
  },

  created() {
    bus.$on('goodsAmountUpdated', this.setGoodsAmount)
  },

  methods: {
    showFilter(name) {
      this.hideFilters()
      this.filtersDisplayed[name] = true
    },

    hideFilters() {
      Object.keys(this.filtersDisplayed).forEach((key, index) => {
        this.filtersDisplayed[key] = false
      })
    },

    updateFilters(type, value) {
      this.internalFilters[type] = _.cloneDeep(value)
      this.$emit('update', _.cloneDeep(this.internalFilters))
    },

    onSearchInput: _.debounce(function(value) {
      this.internalFilters['search_text'] = value
      this.$emit('update', _.cloneDeep(this.internalFilters))
    }, 500),

    resetFilters() {
      this.internalFilters = defaultFilters
      this.$emit('update', _.cloneDeep(this.internalFilters))
    },

    hideFilterPanel() {
      this.show = false
    },

    setGoodsAmount(amount) {
      this.goodsAmount = amount
    }
  },

  computed: {
    numberOfActiveFilters() {
      const arrayFilters = [
        'partners'
      ]

      let number = 0

      arrayFilters.forEach(filter => {
        if (this.filters[filter] && this.filters[filter].length > 0) {
          number++
        }
      })

      return number
    },

    ...mapGetters('handbooks', {
      availablePartners: 'getAvailablePartners',
    })
  },

  watch: {
    filters: {
      handler(value) {
        this.internalFilters = _.cloneDeep(value)
        this.sorting  = value.sorting
      },
      immediate: true, // This is important
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .catalogue-mobile-filters {
    .header {
      margin-bottom: 20px;
      padding: 20px;

      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 22px;
      color: $primary-color;

      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(235,235,235,0.50);
    }

    .content {
      padding: 20px 0 20px 20px;

      background: #FEFEFE;

      & > .header {
        display: flex;
        justify-content: space-between;

        margin: 0 20px 20px 0;

        .close {
          display: flex;

          .close-text {
            display: flex;
            align-items: center;

            .goods-amount {
              margin-left: 3px;
              color: #B7B7B7;
              font-size: 12px;
            }
          }

          svg {
            margin-right: 10px;
          }
        }

        .reset {
          text-transform: uppercase;
        }

        a {
          font-size: 16px;
        }
      }

      .sorting {
        padding-right: 20px;
        margin-bottom: 20px;
      }

      .search {
        margin: 0 20px 20px 0;
      }

      .filter {
        position: absolute;
        z-index: map-get($zIndexes, filter);
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        background-color: #fff;
      }
    }
  }
</style>
