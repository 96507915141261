// const defaultGender  = 'female'
const defaultSorting = 'empty'

export default {
  props: {
    gender: {
      required: true,
      type: String
    }
  },

  /**
   * Get filters from query or set default values.
   *
   * @returns Object
   */
  data() {
    let partners = []

    if (this.$route.query.partner_id) {
      let queryPartners = this.$route.query.partner_id.split(',').map(Number)
      partners = partners.filter(partner => queryPartners.includes(partner.partner_id))
    }

    return {
      searchText:  this.$route.query.search_text  || '',
      // gender:      this.$route.query.gender  || defaultGender,
      sorting:     this.$route.query.sorting || defaultSorting,

      filters: {
        partners,
        sorting: this.$route.query.sorting || defaultSorting,
      }
    }
  },

  methods: {
    applyFiltersAfterTimeout: _.debounce(function(query) {
      this.$router.push({ route: 'catalogue', query }).catch(() => {})
    }, 500)
  },

  computed: {
    ...mapGetters('handbooks', [
      'getClothingCategoryById'
    ]),

    ...mapGetters('handbooks', [
      'getClothingSubCategoryById'
    ]),

    ...mapGetters('handbooks', {
      partners: 'getPartners'
    }),

    clothingItemsTitle() {
      if (this.clothingCategoryId) {
        return this.getClothingCategoryById(this.clothingCategoryId).title
      }

      if (this.clothingSubCategoryId) {
        return this.getClothingSubCategoryById(this.clothingSubCategoryId).title
      }

      if (this.$route.query.search_text) {
        return this.$t('catalogue.search')
      }
    }
  },

  watch: {
    // gender: {
    //   handler: function(gender) {
    //     let query = _.omit({...this.$route.query, ...{ gender }}, [
    //       'clothing_category_id',
    //       'clothing_subcategory_id',
    //       'is_sale',
    //       'is_liked',
    //       'is_new',
    //       'search_text'
    //     ])
    //
    //     this.$router.push({ route: 'catalogue', query })
    //   },
    //   immediate: true
    // },

    sorting: {
      handler: function(sorting) {
        // Important to keep the spread operator, otherwise won't work as expected
        // @see https://github.com/vuejs/vue-router/issues/2047
        let query = {...this.$route.query}

        if (sorting === 'empty') {
          delete query.sorting
        } else {
          query = {...this.$route.query, ...{ sorting }}
        }

        this.$router.push({ route: 'catalogue',  query }).catch(() => {})
      },
      immediate: true
    },

    filters(filters, oldFilters) {
      // So that we do not send empty values to server as it doesn't handle them properly
      let query = _.omit({ ...this.$route.query },
        [
          'partner_id',
          'search_text'
        ]
      )

      if (filters.partners.length > 0) {
        query.partner_id = filters.partners.map(item => item.partner_id).join(',')
      }

      if (filters.sorting && filters.sorting !== 'empty') {
        query.sorting = filters.sorting
      }

      if (filters.search_text !== '') {
        query.search_text = filters.search_text
      }

      this.applyFiltersAfterTimeout(query)
    }
  }
}
