<template>
  <div class="catalogue-desktop" ref="catalogue">
    <div class="main-area">
      <catalogue-desktop-categories class="category-tree sticky"
                                    ref="categoryTree"
                                    :gender="gender"></catalogue-desktop-categories>
      <div class="right-section">
        <div class="controls sticky" ref="controls">
          <div class="quick-filters">
            <clothing-gender-select v-model="selectedGender"
                                    class="gender"></clothing-gender-select>
          </div>
          <div class="tray">
            <base-text-box-search :placeholder="$t('studio.catalogue.searchPlaceholder')"
                                  @input="onSearchInput"></base-text-box-search>
            <clothing-filters v-model="filters"
                              class="filters-icon"></clothing-filters>
          </div>
        </div>

        <catalogue-banners />
        <catalogue-clothing-items ref="clothingItems"
                                  :title="clothingItemsTitle"
                                  :filters="$route.query" />
      </div>
    </div>
  </div>
</template>

<script>
import CatalogueBanners from './Banners/Banners.vue'
import CatalogueDesktopCategories from './CatalogueDesktopCategories.vue'
import CatalogueClothingItems from '../CatalogueClothingItems.vue'
import ClothingGenderSelect from '@/components/Shared/ClothingGenderSelect.vue'
import ClothingFilters from '@/components/Shared/ClothingFilters/ClothingFilters.vue'

import Stickyfill from 'stickyfilljs'
import catalogueMixin from '../catalogueMixin.js'

export default {
  components: {
    CatalogueBanners,
    CatalogueDesktopCategories,
    CatalogueClothingItems,
    ClothingGenderSelect,
    ClothingFilters
  },

  mixins: [
    catalogueMixin
  ],

  data() {
    return {
      selectedGender: this.gender,
      lastScrollPosition: 0
    }
  },

  watch: {
    selectedGender(gender) {
      this.$router.push({ name: 'catalogue', params: { gender }})
    }
  },

  mounted() {
    Stickyfill.add(this.$refs.categoryTree.$el)

    window.addEventListener('scroll', this.adjustCategoryTreePosition)
  },

  methods: {
    onSearchInput: _.debounce(function(value) {
      let query = {...this.$route.query, ...{ search_text: value }}

      // Otherwise server will return empty list
      if (!value) {
        delete query.search_text
      }

      this.$router.replace({ to: 'catalogue', query })
    }, 500),

    adjustCategoryTreePosition() {
      let categoryTree = this.$refs.categoryTree.$el
      let categoryTreeStyles = categoryTree.style
      let scrollPosition = window.pageYOffset
      let defaultTopPosition = categoryTree.parentNode.getBoundingClientRect().top + scrollPosition
      let minimalTopPosition = window.innerHeight - categoryTree.offsetHeight
      let scrollDifference = this.lastScrollPosition - scrollPosition
      let currentTopPosition = +categoryTreeStyles.top.replace('px', '') + scrollDifference

      this.lastScrollPosition = scrollPosition

      if (currentTopPosition >= defaultTopPosition) {
        categoryTreeStyles.top = defaultTopPosition + 'px'

        return
      }

      if (currentTopPosition <= minimalTopPosition) {
        categoryTreeStyles.top = minimalTopPosition + 'px'

        return
      }

      categoryTreeStyles.top = currentTopPosition + 'px'
    },
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.adjustCategoryTreePosition)
  }
}
</script>

<style lang="scss" scoped>


  .catalogue-desktop {
    padding: 40px;
    position: relative;

    .main-area {
      display: flex;
    }

    .category-tree {
      flex-basis: 200px;
      flex-shrink: 0;
    }

    .right-section {
      display: flex;
      flex-direction: column;
      flex: 1 0;

      .controls {
        top: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        margin-top: -40px;
        margin-bottom: 20px;
        padding: 30px 0 10px 0;
        background-color: #fff;
        z-index: map-get($zIndexes, catalogue-controls);

        &.are-fixed {
          position: fixed;
          z-index: 1;
          top: 70px;
          left: 240px;
        }

        .quick-filters {
          display: flex;

          .gender, .sorting {
            margin-bottom: 0;

            /deep/ .tag {
              margin-bottom: 0;
            }
          }

          .sorting {
            margin-left: 20px;
          }
        }

        .tray {
          display: flex;

          .filters-icon {
            margin-left: 8px;
          }

          margin-bottom: 4px;
        }
      }
    }
  }
</style>
